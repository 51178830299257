.logoImg{
    width: 350px;
    height: 150px;
    border-radius: 8px;
}

.logoTextDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.spanSize{
    font-size:75%;
}
.logoDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid;
    border-width: 1px;
    border-color: antiquewhite;
    background-color: white;
}