.pointer{
    cursor: pointer;
}

.current{
    font-weight: bold;
}

.hide{
    display: none;
}

.bachground{
    background-color: lightgrey !important;
}

a{
    color: rgba(0, 0, 0, 0.5) !important;
    text-decoration: none !important;
    cursor: pointer;
}

.dropdown{
    margin-top: 8px;
}